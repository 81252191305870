export default function Sketch(p5) {
    let canvas

    p5.setup = () => {
        // canvas = p5.createCanvas(400, 400);
        canvas = p5.createCanvas(p5.windowWidth, p5.windowHeight);
        p5.background(40);
        // p5.saveCanvas(canvas, "fld.jpg");
    };

    p5.draw = () => {
        p5.background(40);
        p5.noFill();
        p5.strokeWeight(1);
        p5.stroke(255);
        for (let i = 0; i < 2000; i += 10) {
            p5.rect(p5.width / p5.width * p5.random(i++ * 2, i-- * 2), p5.height / p5.height, i, i);
        }
        for (let i = 0; i < 200; i += 10) {
            p5.rect(p5.width / p5.random(i++ * 2, i-- * 2), p5.height / p5.height, i, i);
        }
        for (let i = 0; i < 2000; i += 10) {
            p5.rect(p5.width / p5.random(i++ * 20, i-- * 2), p5.height / p5.height, i, i++ + p5.random(i / 10, -i / 10));
        }
    };

    p5.windowResized = () => {
        p5.resizeCanvas(p5.windowWidth, p5.windowHeight);
    }

};
